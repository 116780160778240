<template>
	<div class="cell" :class="[celltype, {'shades' : shades.indexOf(hourint) > -1}]" :style="'width: '+this.cellWidth+';'">
		<div class="innards" :style="'background-color: '+this.cellColour+';'">
			<div v-if="celltype === 'uplift'" style="padding-bottom: 3px;">
				<i class="mdi mdi-arrow-up-thick arrowup"></i>
				<div>{{cellval}}%</div>
			</div>
			<div v-else :class="{standardvaltext : celltype !== 'circle'}"><span :class="textColorOverride">{{cellval}}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HourCell",
	components: {},
	props: {
		celltype: String,
		cellval: Number,
		rangename: String,
		hourint: Number,
	},
	data: function () {
		return {
			colors: [
				'#790002',
				'#AB121C',
				'#E3705C',
				'#E6A597',
				'#FFFFFF',
				'#8CC088',
				'#668683',
				'#00A850',
				'#238351',
				'#005C27',
			],
			widths: ['64%','68%','72%','76%','80%','84%','88%','92%','96%','100%'],
			shades: [9,10,11,16,17,18],
		}
	},
	computed: {
		range() {
			// return this.$store.state.rangeData[this.rangename]; //use this for generated ranges - custom to the data type
			return {
				p1: 76,
				p2: 82,
				p3: 88,
				p4: 94,
				p5: 100,
				p6: 106,
				p7: 112,
				p8: 118,
				p9: 124,
			}; //use this for consistent colours across all metrics
		},
		activeRangeIndex() {
			if(this.range) {
				for (var i = 1; i <= 9; i++) {
					var rpropname = "p" + i;
					var topRangeVal = this.range[rpropname];
					if (this.cellval <= topRangeVal) {
						return i - 1;
					}
				}
				return 9;
			}
			return false;
		},
		textColorOverride() {
			var dowhites = [1,2,6,7,8,9];
			if(dowhites.includes(this.activeRangeIndex)) {
				return 'text-white';
			}
			return '';
		},
		cellColour() {
			if(this.activeRangeIndex) {
				return this.colors[this.activeRangeIndex];
			}
			return "transparent";
		},
		cellWidth() {
			if(this.celltype === 'colourbar') {
				return this.widths[this.activeRangeIndex];
			}
			return '100%';
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.cell {
	width: 100%;
	padding: 4px 0;
}
.innards {
	display: flex;
	align-items: center;
}
.circle {
	display: flex;
	justify-content: center;
}
.circle .innards {
	border-radius: 50%;
	height: 50px;
	width: 50px;
	border: 1px solid #CCC;
	text-align: center;
	justify-content: center;
}
.uplift .innards {
	justify-content: center;
}
.colourbar {

}
.colourbar .innards {
	height: 16px;
}
.colourcell {
	padding: 0;
}
.colourcell .innards {
	height: 22px;
}
.arrowup {
	color: #238351;
	font-size: 28px;
}
.standardvaltext {
	text-align: left;
	padding-left: 5px;
}
.cell.uplift.shades,
.cell.circle.shades {
	background-color: #DDD;
}
</style>