<template>
	<div>
		<NavMenu></NavMenu>
		<div class="admin-holder">
			<Users v-if="activeAdminSection === 'users'"></Users>

		</div>

	</div>
</template>

<script>
import NavMenu from "@/components/NavMenu";
// import axios from "axios";
import Users from "@/components/admin/Users";
export default {
	name: "Admin",
	components: {
		NavMenu,
		Users,
	},
	props: {},
	data: function () {
		return {}
	},
	computed: {
		activeAdminSection() {
			return this.$store.state.activeSectionAdmin;
		}
	},
	methods: {

	},
	watch: {},
	mounted() {
		if(this.$store.state.user.isAdmin !== 1) {
			this.$router.push('/dashboard');
			return false;
		}
	}
}
</script>

<style scoped>
.admin-holder {
	width: calc(100% - 20px);
	margin: 80px auto 0;
	height: calc(100vh - 90px);
}
</style>