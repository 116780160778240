<template>
	<div class="loader-dots">
		<div class="loader-dot"></div>
		<div class="loader-dot"></div>
		<div class="loader-dot"></div>
	</div>
</template>

<script>
export default {
	name: 'WorkingButtonInsert',
	props: {

	}
}
</script>

<style scoped>
.loader-dots {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	visibility: visible;
	top: 0;
}
.loader-dot {
	height: 12px;
	margin-right: 2px;
	margin-left: 2px;
	width: 12px;
	animation: xui-loader-animation 1.3s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
	animation-fill-mode: both;
	background-color: #FFF;
	border-radius: 50%;
	box-shadow: 0 0 1px #FFF;
	transform-style: preserve-3d;
	vertical-align: middle;
}
.loader-dot-grey {
	background-color: #888;
	border-radius: 50%;
	box-shadow: 0 0 1px #888;
}
.loader-dot:nth-child(1) {
	animation-delay: -.32s;
}
.loader-dot:nth-child(2) {
	animation-delay: -.16s;
}
.loader-dot:nth-child(3) {
	animation-delay: 0s;
}
@keyframes xui-loader-animation {
	0%,80%,100% {transform:scale(0.5) rotate(0deg)}
	40%{transform:scale(1) rotate(0.02deg)}
}
</style>
