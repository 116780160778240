<template>
	<div>
		<NavMenu></NavMenu>
		<div class="below-navbars">
			<div class="content-container">

				<div class="top-selections-hold">

					<div class="select-holder">
						<p class="select-label">Category</p>
						<b-form-select v-model="selectedCategory" :options="$store.state.categoryoptions"></b-form-select>
					</div>
					<div class="select-holder">
						<p class="select-label">Day of Week</p>
						<b-form-select v-model="selectedDayOfWeek" :options="$store.state.dayofweekoptions"></b-form-select>
					</div>
					<div class="select-holder">
						<p class="select-label">Gender</p>
						<b-form-select v-model="selectedGender" :options="$store.state.genderoptions"></b-form-select>
					</div>
					<div class="select-holder">
						<p class="select-label">Age</p>
						<b-form-select v-model="selectedAge" :options="$store.state.ageoptions"></b-form-select>
					</div>
					<div class="select-holder">
						<p class="select-label">G/B</p>
						<b-form-select v-model="selectedGrocBuyer" :options="$store.state.grocbuyeroptions"></b-form-select>
					</div>

					<div class="button-holder">
						<span @click="doLookup" class="link-btn" :class="{'button-is-working' : updating === true}">
							<span>Process</span>
							<WorkingButtonInsert v-if="updating"></WorkingButtonInsert>
						</span>
					</div>

				</div>

				<div style="padding: 0 0 20px 0; ">
					<div class="section-heading">
						<p>Audio Reach - By Channel</p>
					</div>

					<div class="manual-chart">
						<div class="chart-left">
							<p>Net Reach</p>
						</div>
						<div class="main-chart-holder">
							<apexchart v-if="reachChartSeries" type="line" height="350" :options="reachChartOptions" :series="reachChartSeries"></apexchart>
						</div>
					</div>
				</div>

				<div class="tabling">
					<div class="data-row border-top border-bottom">
						<div class="label-cell" style="line-height: 1.3; padding: 5px 0; font-weight: bold;">Digital Audio's Incremental<br/>Reach to Radio</div>
						<HourCells propname="digital_aud_uplift" vistype="uplift" :alldata="returnedData"></HourCells>
					</div>
				</div>

				<div style="padding: 20px 0 20px 0; ">
					<div class="section-heading">
						<p>Audio Reach - Effective Reach</p>
					</div>
					<div class="manual-chart">
						<div class="chart-left">
							<p></p>
						</div>
						<div class="main-chart-holder">
							<apexchart v-if="effectiveReachChartSeries" type="line" height="350" :options="effectiveReachChartOptions" :series="effectiveReachChartSeries"></apexchart>
						</div>
					</div>
				</div>

				<div style="padding: 20px 0 20px 0; ">
					<div class="section-heading">
						<p>Audio Reach - Exclusive During Hour</p>
					</div>
					<div class="manual-chart">
						<div class="chart-left">
							<p></p>
						</div>
						<div class="main-chart-holder">
							<apexchart v-if="exclusiveReachChartSeries" type="line" height="350" :options="exclusiveReachChartOptions" :series="exclusiveReachChartSeries"></apexchart>
						</div>
					</div>
				</div>

				<div class="tabling">
					<div class="section-heading">
						<p>Category Mindset (Relevancy of Message) - {{selectedCategory}}</p>
					</div>
					<div class="data-row border-top">
						<div class="label-cell">Top Of Funnel</div>
						<HourCells propname="topoffunnel" vistype="circle" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row border-bottom">
						<div class="label-cell">Bottom Of Funnel</div>
						<HourCells propname="bottomoffunnel" vistype="circle" :alldata="returnedData"></HourCells>
					</div>

					<div class="section-heading" style="padding-top: 20px;">
						<p>Attention Metrics (Likelihood to Take Notice)</p>
					</div>
					<div class="data-row border-top">
						<div class="label-cell">Alert</div>
						<HourCells propname="alert" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row border-bottom">
						<div class="label-cell">Focussed</div>
						<HourCells propname="focussed" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>

					<div class="section-heading" style="padding-top: 20px;">
						<p>Receptivity Metrics (Likelihood to be Open to Message)</p>
					</div>
					<div class="data-row border-top">
						<div class="label-cell">Calm</div>
						<HourCells propname="calm" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row">
						<div class="label-cell">Relaxed</div>
						<HourCells propname="relaxed" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row border-bottom">
						<div class="label-cell">Happy</div>
						<HourCells propname="happy" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>

					<div class="section-heading" style="padding-top: 20px;">
						<p>Motivational Metrics (Likelihood to Take Immediate Action)</p>
					</div>
					<div class="data-row border-top">
						<div class="label-cell">Motivated</div>
						<HourCells propname="motivated" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row">
						<div class="label-cell">Energised</div>
						<HourCells propname="energised" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row border-bottom">
						<div class="label-cell">Excited</div>
						<HourCells propname="excited" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>

					<div class="section-heading" style="padding-top: 20px;">
						<p>Impact (Likelihood to Connect to Content of Message)</p>
					</div>
					<div class="data-row border-top">
						<div class="label-cell">Approach (uplifting)</div>
						<HourCells propname="approach_mood" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row">
						<div class="label-cell">Engagement (relatable)</div>
						<HourCells propname="engagement_mood" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row">
						<div class="label-cell">Attention (logical)</div>
						<HourCells propname="attention_mind" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>
					<div class="data-row border-bottom">
						<div class="label-cell">Memory (new/retrieved)</div>
						<HourCells propname="memory_mind" vistype="colourcell" :alldata="returnedData"></HourCells>
					</div>

				</div>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import NavMenu from "@/components/NavMenu";
import HourCells from "@/components/HourCells";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
export default {
	name: "Dashboard",
	components: {
		WorkingButtonInsert,
		HourCells,
		NavMenu
	},
	props: {},
	data: function () {
		return {
			updating: false,

			selectedCategory: 'Alcoholic Beverages',
			selectedDayOfWeek: 'M-F',
			selectedGender: 'All',
			selectedAge: '18+ yrs',
			selectedGrocBuyer: 'n/a',

			returnedData: [],
			reachChartOptions: {
				chart: {
					type: 'line',
					height: 350,
					// stacked: true,
					toolbar: {
						show: false
					},
					zoom: {
						enabled: false
					},
					adnimations: {
						enabled: false,
					},
				},
				colors: ['#4e79a6','#a1cae8','#f28f32','#dd5859','#858452'],
				grid: {
					show: true,
					xaxis: {
						lines: {
							show: true
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					},
					padding: {
						right: 0,
					},
				},
				responsive: [{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0
						}
					}
				}],
				plotOptions: {
					bar: {
						horizontal: false,
						// borderRadius: 10
					},
				},
				xaxis: {
					type: 'category',
					labels: {
						show: true,
						formatter: (value) => {
							if(value) return value.split(" ")[0];
							else return value;
						},
						offsetX: -5,
					},
				},
				yaxis: {
					minWidth: 100,
					maxWidth: 100,
					labels: {
						formatter: (value) => { return parseInt(value / 1000) + "k"},
					},
				},
				legend: {
					position: 'top',
					offsetY: 5,
					floating: true,
				},
				fill: {
					opacity: 1
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 0,
				},
				markers: {
					size: 10,
					hover: {
						sizeOffset: 2,
					},
				}
			},

			effectiveReachChartOptions: {
				chart: {
					type: 'line',
					height: 350,
					toolbar: {
						show: false
					},
					zoom: {
						enabled: false
					},
					adnimations: {
						enabled: true,
					},
				},
				colors: ['#4e79a6','#f28f32'],
				grid: {
					show: true,
					xaxis: {
						lines: {
							show: true
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					},
					padding: {
						right: 0,
					},
				},
				responsive: [{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0
						}
					}
				}],
				plotOptions: {
					bar: {
						horizontal: false,
						// borderRadius: 10
					},
				},
				xaxis: {
					type: 'category',
					labels: {
						show: true,
						formatter: (value) => {
							if(value) return value.split(" ")[0];
							else return value;
						},
						offsetX: -5,
					},
				},
				yaxis: {
					minWidth: 100,
					maxWidth: 100,
					labels: {
						formatter: (value) => { return parseInt(value / 1000) + "k"},
					},
				},
				legend: {
					position: 'top',
					offsetY: 5,
					floating: true,
				},
				fill: {
					opacity: 1
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
				},
				markers: {
					size: 4,
					// hover: {
					// 	sizeOffset: 2,
					// },
				}
			},

			exclusiveReachChartOptions: {
				chart: {
					type: 'line',
					height: 350,
					toolbar: {
						show: false
					},
					zoom: {
						enabled: false
					},
					adnimations: {
						enabled: true,
					},
				},
				colors: ['#4e79a6','#a1cae8','#f28f32','#dd5859','#858452'],
				grid: {
					show: true,
					xaxis: {
						lines: {
							show: true
						}
					},
					yaxis: {
						lines: {
							show: true
						}
					},
					padding: {
						right: 0,
					},
				},
				responsive: [{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -5,
							offsetY: 0
						}
					}
				}],
				plotOptions: {
					bar: {
						horizontal: false,
						// borderRadius: 10
					},
				},
				xaxis: {
					type: 'category',
					labels: {
						show: true,
						formatter: (value) => {
							if(value) return value.split(" ")[0];
							else return value;
						},
						offsetX: -5,
					},
				},
				yaxis: {
					minWidth: 100,
					maxWidth: 100,
					labels: {
						formatter: (value) => { return parseInt(value / 1000) + "k"},
					},
				},
				legend: {
					position: 'top',
					offsetY: 5,
					floating: true,
				},
				fill: {
					opacity: 1
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 2,
				},
				markers: {
					size: 4,
					// hover: {
					// 	sizeOffset: 2,
					// },
				}
			},
		}
	},
	computed: {
		reachChartSeries() {
			var dat = [];
			var totaudSeries = [];
			var radioSeries = [];
			var streamedRadioSeries = [];
			var musicStreamingSeries = [];
			var podcastsSeries = [];
			if(this.returnedData.length > 0) {
				for(var i=0; i<this.returnedData.length; i++) { //each is an hour
					var myrow = this.returnedData[i];
					var hourint = myrow.timehourstring;
					totaudSeries.push({x: hourint, y: myrow.tot_aud_rch});
					radioSeries.push({x: hourint, y: myrow.bcastradio});
					streamedRadioSeries.push({x: hourint, y: myrow.streamedradio});
					musicStreamingSeries.push({x: hourint, y: myrow.musicstreaming});
					podcastsSeries.push({x: hourint, y: myrow.podcasts});
				}
				dat = [
					{name: 'Total Audio (Net Reach)', data: totaudSeries},
					{name: 'Broadcast Radio (AM/FM/DAB+)', data: radioSeries},
					{name: 'Streaming Radio (Online)', data: streamedRadioSeries},
					{name: 'Podcasts', data: podcastsSeries},
					{name: 'Music Streaming', data: musicStreamingSeries},
				];
				return dat;
			}
			return false;
		},
		effectiveReachChartSeries() {
			var dat = [];
			var totaudSeries = [];
			var indexedByAttention = [];
			if(this.returnedData.length > 0) {
				for(var i=0; i<this.returnedData.length; i++) { //each is an hour
					var myrow = this.returnedData[i];
					var hourint = myrow.timehourstring;
					totaudSeries.push({x: hourint, y: myrow.tot_aud_rch});
					indexedByAttention.push({x: hourint, y: myrow.tot_aud_attention_index});
				}
				dat = [
					{name: 'Total Audio (Net Reach)', data: totaudSeries},
					{name: 'Total Audio (Indexed by Attention)', data: indexedByAttention},
				];
				return dat;
			}
			return false;
		},
		exclusiveReachChartSeries() {
			var dat = [];
			var totaudSeries = [];
			var exclooh = [];
			var excltv = [];
			var excltvAndOoh = [];
			if(this.returnedData.length > 0) {
				for(var i=0; i<this.returnedData.length; i++) { //each is an hour
					var myrow = this.returnedData[i];
					var hourint = myrow.timehourstring;
					totaudSeries.push({x: hourint, y: myrow.tot_aud_rch});
					exclooh.push({x: hourint, y: myrow.audrch_excl_ooh});
					excltv.push({x: hourint, y: myrow.audrch_excl_tv});
					excltvAndOoh.push({x: hourint, y: myrow.audrch_excl_tvooh});
				}
				dat = [
					{name: 'Total Audio (Net Reach)', data: totaudSeries},
					{name: 'Audio Reach - Exclusive of OOH', data: exclooh},
					{name: 'Audio Reach - Exclusive of TV', data: excltv},
					{name: 'Audio Reach - Exclusive of TV + OOH', data: excltvAndOoh},
				];
				return dat;
			}
			return false;
		},
		u() {
			return this.$store.state.user;
		}
	},
	methods: {
		doLookup() {
			if(this.updating === false) {
				this.updating = true;
				var self = this;
				var targ = "/post/get-data.php";
				var formdata = {
					category: this.selectedCategory,
					dayofweek: this.selectedDayOfWeek,
					gender: this.selectedGender,
					age: this.selectedAge,
					grocbuyer: this.selectedGrocBuyer,
				};
				axios.post(targ, formdata).then(function (response) {
					var ret = response.data; //response.data is returned info
					if (ret.error === 1) {
						self.$store.commit('showKalert', ret);
						console.log(ret);
					}
					else {
						if (ret.returndata != null) {
							self.returnedData = ret.returndata;
						}
						if (ret.ranges != null) {
							self.$store.commit('setRangeData', ret.ranges);
						}
					}
					setTimeout(function () {
						self.updating = false;
					}.bind(self), 500);
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {
		this.doLookup();
	},
	created() {
		document.title = "Dashboard - ARN Planning Tool";

	},
}
</script>

<style scoped>
.top-selections-hold {
	display: flex;
	width: 100%;
	padding-bottom: 10px;
}
.select-holder, .button-holder {
	padding: 0 10px 0 0;
}
.select-holder .select-label {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 8px;
}
.button-holder {
	display: flex;
	align-items: flex-end;
	margin-bottom: 1px;
}
.button-holder .link-btn {
	display: block;
}

.section-heading {
	padding: 10px 0;
}
.section-heading p {
	font-size: 16px;
	font-weight: bold;
}

.manual-chart {
	height: 350px;
	border-bottom: 1px solid #DDD;
	display: flex;
}
.manual-chart .chart-left {
	padding: 20px;
	display: flex;
	align-items: center;
}
.manual-chart .chart-left p {
	text-align: center;
}
.main-chart-holder {
	width: 100%;
	margin-left: 2.4%;
	margin-right: 1.8%;
}

.data-row {
	width: 100%;
	display: flex;
}
.data-row.border-top {
	border-top: 1px solid #DDD;
}
.data-row.border-bottom {
	border-bottom: 1px solid #DDD;
}
.label-cell {
	width: 300px;
	border-right: 1px solid #DDD;
	display: flex;
	align-items: center;
}
.manual-chart .chart-left {
	width: 200px;
	flex-shrink: 0;
	flex-grow: 0;
}


</style>