<template>
	<div class="nav-holder">
		<div class="top-row">
			<div class="content-container">
				<div class="left-top-menu">
					<div class="nav-logo-holder">
						<router-link to="/dashboard">
							<img src="@/assets/arn-logo.svg" alt="Logo" class="logo-img" />
						</router-link>
					</div>
				</div>
				<div class="right-top-menu">
					<router-link v-if="$store.state.user.isAdmin === 1" to="/admin">
						<span class="link-btn">Admin</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'NavMenu',
	components: {

	},
	props: {

	},
	computed: {
		// routepath() {
		// 	return this.$router.currentRoute.path;
		// }
	},
	methods: {

	},
}
</script>

<style scoped>
.nav-holder {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	z-index: 13;
}
.top-row {
	height: 70px;
	width: 100%;
	background-color: #EAEAEA;
	position: relative;
}
.top-row .content-container {
	display: flex;
	justify-content: space-between;
	height: 100%;
}
.left-top-menu {
	display: flex;
	align-content: center;
}
.left-top-menu a {
	display: flex;
	align-items: center;
}
.right-top-menu {
	display: flex;
	align-items: center;
}
.right-top-menu .nav-item {
	text-transform: uppercase;
	color: #cfd3da;
	font-size: 13px;
	font-weight: 500;
	cursor: pointer;
}
.right-top-menu .nav-item:hover {
	color: #fff;
}
.nav-logo-holder {
	display: flex;
	align-items: center;
}
.logo-img {
	height: 56px;
}
.nav-item {
	padding: 5px 10px;
}
</style>
