import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Login from "@/components/pageviews/Login";
import Logout from "@/components/pageviews/Logout";
import Dashboard from "@/components/pageviews/Dashboard";
import Admin from "@/components/pageviews/Admin";

import './includes/style.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueApexCharts)
Vue.config.productionTip = false

Vue.component('apexchart', VueApexCharts)

//routing
const routes = [
	{path: '/login', component: Login},
	{path: '/logout', component: Logout},
	{path: '/dashboard', component: Dashboard},
	{path: '/admin', component: Admin},
	// {path: '/*', component: ErrorPage},
];

const router = new VueRouter({
	history: true,
	hashbang: false,
	base: process.env.BASE_URL,
	routes: routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const store = new Vuex.Store({
	state: {
		sesscheckcomplete: false,
		kalert: {
			shown: false,
			text: "",
			type: 'danger',
		},
		user: {
			id: 0,
		},
		activeSection: '',
		getparams: {},

		ageoptions: [],
		genderoptions: [],
		categoryoptions: [],
		dayofweekoptions: [],
		grocbuyeroptions: [],
		rangeData: {},
		activeSectionAdmin: 'users',
	},
	getters: {},
	mutations: {
		setGetParams(state, obj) {
			state.getparams = obj;
		},
		showKalert(state, responseObject) {
			state.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) state.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			state.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) state.kalert.type = responseObject.type;
			state.kalert.shown = true;
		},
		closeKalert(state) {
			state.kalert.shown = false;
		},
		setSesscheckComplete(state, sesscheckbool) {
			state.sesscheckcomplete = sesscheckbool;
		},
		// setActiveSection(state, sectionname) {
		//   state.activeSection = sectionname;
		// },
		setUser(state, userob) {
			state.user = userob;
		},
		setInitAgeList(state, listarr) {
			state.ageoptions = listarr;
		},
		setInitGenderList(state, listarr) {
			state.genderoptions = listarr;
		},
		setInitCategoryList(state, listarr) {
			state.categoryoptions = listarr;
		},
		setInitGrocBuyerList(state, listarr) {
			state.grocbuyeroptions = listarr;
		},
		setInitDayOfWeekList(state, listarr) {
			state.dayofweekoptions = listarr;
		},
		setRangeData(state, obj) {
			state.rangeData = obj;
		},
	},
	actions: {},
});

Vue.mixin({
	methods: {
		numdisplay: function (num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		// switchAdminTab(newtabname) {
		// 	this.$store.commit('setActiveSectionAdmin', newtabname);
		// 	this.fetchAdminData();
		// },
		// fetchAdminData() {
		// 	var self = this;
		// 	var targ = '/post/get-admin-data.php';
		// 	axios.get(targ).then(function(response) {
		// 		var ret = response.data; //response.data is returned info
		// 		self.$store.commit('setFromAdminReturns', ret);
		// 	}).catch(function(error) {
		// 		console.log(error);
		// 	});
		// },
	},
});

//initialise app
new Vue({
	router,
	store: store,
	render: h => h(App),
}).$mount('#app')
