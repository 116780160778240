<template>
	<div id="app">
		<AlertTop v-bind:obj="$store.state.kalert"></AlertTop>
		<NavMenu v-if="$store.state.user.id > 0 && $store.state.sesscheckcomplete"></NavMenu>
		<router-view v-if="$store.state.sesscheckcomplete"></router-view>
	</div>
</template>

<script>
import axios from "axios";
import AlertTop from "@/components/AlertTop";
import NavMenu from "@/components/NavMenu";

export default {
	name: 'App',
	components: {
		NavMenu,
		AlertTop
	},
	data: function () {
		return {
			requestedRoute: '',
		}
	},
	methods: {
		sesscheck() {
			let self = this;
			let formdata = {
				getparams: this.$store.state.getparams,
				requestedroute: this.requestedRoute,
			};
			axios.post(
				"/post/firstrun.php", formdata
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(ret.user) {
					self.$store.commit("setUser", ret.user);
				}
				if(ret.lists.ageoptions && ret.lists.ageoptions.length > 0) {
					self.$store.commit('setInitAgeList', ret.lists.ageoptions);
					self.$store.commit('setInitGenderList', ret.lists.genderoptions);
					self.$store.commit('setInitCategoryList', ret.lists.categoryoptions);
					self.$store.commit('setInitGrocBuyerList', ret.lists.grocbuyeroptions);
					self.$store.commit('setInitDayOfWeekList', ret.lists.dayofweekoptions);
				}
				if(ret.sessionok === 0) { //transfer to login screen
					if(self.$router.currentRoute.path !== '/login') self.$router.push('/login');
				}
				else {
					if(self.$router.currentRoute.path === '/') self.$router.push('/dashboard');
				}
				self.$store.commit('setSesscheckComplete', true);
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	mounted() {
		this.requestedRoute = this.$router.currentRoute.path;
		this.sesscheck();
	},
	created() {
		//handle incoming GET parameters
		let urlparams = this.$route.query;
		this.$store.commit('setGetParams', urlparams);
		if(urlparams.e !== undefined) {
			this.$store.commit('setRegistrationEmail', urlparams.e);
		}
	},
	watch: {
		$route() {
			//force close alert on page change
			this.$store.commit('closeKalert');
		}
	},
}
</script>

<style>

</style>
