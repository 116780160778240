<template>
	<div class="hour-cells">
		<div v-for="(hrob, index) in alldata" :key="index" class="hour-cell">
			<HourCell :celltype="vistype" :cellval="hrob[propname]" :rangename="propname" :hourint="hrob.timehourint"></HourCell>
		</div>
	</div>
</template>

<script>
import HourCell from "@/components/HourCell";
export default {
	name: "HourCells",
	components: {
		HourCell
	},
	props: {
		alldata: Array,
		propname: String,
		vistype: String,
	},
	data: function () {
		return {

		}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.hour-cells {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.hour-cell {
	border-right: 1px solid #CCC;
	text-align: center;
	width: 100%;
}
</style>