<template>
	<div class="kalert-top" style="">
		<b-alert :variant="obj.type" v-model="obj.shown">
			<component :is="compiledText"></component>
			<div v-on:click="closeAlert" class="float-right icon-click">
				<i class='mdi mdi-close'></i>
			</div>
		</b-alert>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'AlertTop',
	props: {
		obj: Object,
	},
	computed: {
		compiledText() {
			return {
				template: '<span>'+this.obj.text+'</span>',
				methods: {
					resendActivation() {
						this.$store.commit("showKalert", {shown: true, message: "Working...", alerttype: 'info'});
						var self = this;
						var em = this.$store.state.registrationEmail;
						if(em !== "") {
							axios.post(
								"post/resend-activation-email.php",
								JSON.stringify({emailval: em})
							).then(function (response) {
								self.loginFormWorking = false;
								var ret = response.data; //response.data is returned info
								self.$store.commit("showKalert", ret);
							}).catch(function (error) {
								console.log(error);
							});
						}
					},
				},
			}
		},
	},
	methods: {
		closeAlert() {
			this.$store.commit('closeKalert');
		},
	},
}
</script>

<style scoped>
.kalert-top {
	width: 80%;
	margin: 0 auto;
	z-index: 998;
	position: fixed;
	left:10%;
	top:16px;
}
.kalert-top .alert {
	padding-top: 20px;
	padding-bottom: 20px;
}
.icon-click {
	cursor: pointer;
}
.icon-click i {
	transition: all 0.3s ease-in-out;
}
.icon-click:hover i {
	color: #BBB;
}
</style>
